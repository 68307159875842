
/**
 *  use this class for pages with <footer-toolbar>
 */
.content-page {
    display: block;
    position: relative;
    // height: calc(100% - 40px)!important;
    padding: 0px !important;
}

/**
 *  Use this class for page title
 */



/**
 *   TOAST CSS
 */

ion-toast.toast-success::part(container) {
  background-color: #32db64 !important;
  border-radius: 10px !important;
}

ion-toast.toast-danger::part(container) {
  background-color: var(--omni-custom-red) !important;
  border-radius: 10px !important;
}

// #region checkbox css
/**
 * Checkbox CSS
 */
.round {
    position: relative;
}

.round label {
    background-color: var(--omni-custom-white);
    border: 1px solid #ccc;
    border-radius: 50%;
    cursor: pointer;
    height: 21px;
    left: 0;
    position: absolute;
    width: 21px;

}

.auto-selected {
    background-color: rgba(179, 180, 182, 0.884) !important;
}

.genie-selected{
    background-color: var(--omni-primary-color2) !important;
}

.round label:after {
    border: 1px solid var(--omni-custom-white);
    border-top: none;
    border-right: none;
    content: "";
    height: 3px;
    left: 6px;
    opacity: 0;
    position: absolute;
    top: 8px;
    transform: rotate(-45deg);
    width: 8px;
}

.round input[type="checkbox"] {
    visibility: hidden;
}

.round input[type="checkbox"]:checked + label { // initial selected background
    background-color: var(--omni-custom-blue);
    border: none;
}

.round input[type="checkbox"]:checked + label:after {
    opacity: 1;
}
//grey out round button
.round-disabled {
    position: relative;
}

.round-disabled label {
    color: #DCDAD1;
    border: 1px solid #ccc;
    border-radius: 50%;
    cursor: pointer;
    height: 21px;
    left: 0;
    position: absolute;
    width: 21px;
}

.round-disabled label:after {
    border: 1px solid var(--omni-custom-white);
    border-top: none;
    border-right: none;
    content: "";
    height: 3px;
    left: 6px;
    opacity: 0;
    position: absolute;
    top: 8px;
    transform: rotate(-45deg);
    width: 8px;
}


.round-disabled input[type="checkbox"] {
    visibility: hidden;
}

.round-disabled input[type="checkbox"]:checked + label {
    // background-color:rgba(202, 223, 247, 0.877);
    background-color: #1B75BB;
    border: none;
}

.round-disabled input[type="checkbox"]:checked + label:after {
    opacity: 1;
}

/**
 *  END of Checkbox CSS
 */
// #endregion checkbox css


.primary-text {
    font-size: var(--omni-primary-text-font-size);
}

.secondary-text {
    font-size: var(--omni-secondary-text-font-size)
}

/**
 *  ion-loader padding
 */
.loading-wrapper {
    padding: 24px 24px !important;
}
