/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */


/* Core CSS required for Ionic components to work properly */

@import "@ionic/angular/css/ionic.bundle.css";
@import 'theme/variables.css';
// /* Basic CSS for apps built with Ionic */
// @import "~@ionic/angular/css/normalize.css";
// @import "~@ionic/angular/css/structure.css";
// @import "~@ionic/angular/css/typography.css";
// @import '~@ionic/angular/css/display.css';
// /* Optional CSS utils that can be commented out */
// @import "~@ionic/angular/css/padding.css";
// @import "~@ionic/angular/css/float-elements.css";
// @import "~@ionic/angular/css/text-alignment.css";
// @import "~@ionic/angular/css/text-transformation.css";
// @import "~@ionic/angular/css/flex-utils.css";

/*
 * App CSS
 * ----------------------------------------------------------------------------
 * Imports a file that can contain Sass/CSS that should be used throughout
 * the entire app.
 */

@import "./app/app.scss";
@font-face {
    font-family: "Rubik-Regular";
    src: url(./assets/font/Rubik/Rubik-Regular.ttf);
}

@font-face {
    font-family: "Rubik-Light";
    src: url(./assets/font/Rubik/Rubik-Light.ttf);
}

@font-face {
    font-family: "Roboto";
    src: url(./assets/font/Roboto/roboto-v18-latin-regular.ttf);
}

@font-face {
    font-family: "SF-Pro-Rounded-Bold";
    src: url(./assets/font/SF-Pro/SF-Pro-Rounded-Bold.ttf);
}

@font-face {
    font-family: "SF-Pro-Rounded-Semibold";
    src: url(./assets/font/SF-Pro/SF-Pro-Rounded-Semibold.ttf);
}

:root {
    --ion-font-family: "Rubik-Regular", -apple-system, BlinkMacSystemFont, "Segoe UI", "Arial", "Helvetica", "Sans-serif";
    --omni-font-family-base: var(--ion-font-family);
}

//fix for popover not shown in chrome-114 due to browser popover collide
ion-popover [popover]:not(:popover-open):not(dialog[open]) {
    display: contents;
}

//genee popover styles
.geneeCallNotes,
.geneeNotesSuggestions,
.geneeSyncConfirmation,
.geneeActionConfirmation {
    .popover-wrapper {
        //width: 100% !important;
    }
    &::part(content) {
        background-image: url(./assets/imgs/sync-pattern@svg.svg);
        background-color: rgba(14, 14, 14, 0.9);
        background-size: contain;
        background-repeat: no-repeat;
        border-radius: 0% !important;
        border-top-left-radius: 20px !important;
        color: --omni-custom-white;
        padding: 20px;
        padding-bottom: 0;
    }

    .actionButtonContainer {
      span {
          display: table;
          align-items: center;
      }
      ion-label {
          margin: -8px !important;
      }
      img {
          margin-top: 10px !important;
          position: absolute;
      }
    }
}

.geneeCallNotes,
.geneeNotesSuggestions,
.geneeSyncConfirmation,
.geneeActionConfirmation {
    p {
        @media only screen and (min-width: 620px) {
            font-size: 17px !important;
        }
        @media only screen and (max-width: 619px) {
            font-size: 15px !important;
        }
    }
    .geneeActionButtons {
        background-color: #484c42;
        font-size: 15px;
        border-radius: 50px !important;
        height: 30px !important;
        ion-label {
            white-space: nowrap !important;
        }
    }
    .geneeActionButtons.mainButton {
        background-color: var(--omni-genee-suggestions-border) !important;
    }
    .geneeActionButtons:active {
        background-color: var(--omni-genee-suggestions-border) !important;
    }
}

.geneeCallNotes,
.geneeNotesSuggestions {
    top: 75% !important;
    bottom: 0 !important;
    height: calc(25%);
    //
    @media only screen and (min-width: 620px) {
        width: 60% !important;
        left: 40% !important;
    }
    @media only screen and (max-width: 619px) {
        width: 100% !important;
        left: 0% !important;
    }
    //
}

.geneeSyncConfirmation,
.geneeSyncInProgress,
.geneeActionConfirmation {
    top: 0 !important;
    bottom: 0 !important;
    height: 100% !important;
    width: 100% !important;
    --backdrop-opacity: 0.5;
}

.geneeCallNotes {
  &::part(content) {
        width: 100% !important;
        max-width: 100% !important;
        height: 100% !important;
        max-height: 100% !important;
        left: 0 !important;
        right: 0 !important;
        top: 0 !important;
    }
}

.geneeSyncConfirmation,
.geneeActionConfirmation {
  &::part(content) {
        left: 0 !important;
        height: 25% !important;
        top: unset !important;
        bottom: 0 !important;
        @media only screen and (max-width: 325px) and (max-height: 812px) {
            height: 25% !important;
        }
        @media only screen and (min-width: 620px) {
            width: 40% !important;
            max-width: 40% !important;
            right: 60% !important;
        }
        @media only screen and (max-width: 619px) {
            width: 100% !important;
            max-width: 100% !important;
            // right: 60% !important;
        }
        @media (min-width: 610px) {
            max-height: 220px;
        }
        @media (min-width: 610px) and (min-aspect-ratio: 1/1) {
            max-height: unset;
        }
        // @media (min-aspect-ratio: 1/1) {
        //   max-height: unset;
        // }
        //
    }
}
.geneeSyncInProgress{
  &::part(content) {
        left: 0 !important;
        height: 0 !important;
        top: unset !important;
        bottom: 0 !important;
        padding: 0 !important;
        @media only screen and (max-width: 325px) and (max-height: 812px) {
            height: 0 !important;
        }
        @media only screen and (min-width: 620px) {
            width: 0 !important;
            max-width: 0 !important;
            right: 60% !important;
        }
        @media only screen and (max-width: 619px) {
            width: 0 !important;
            max-width: 0 !important;
            // right: 60% !important;
        }
        @media (min-width: 610px) {
            max-height: 0;
        }
        @media (min-width: 610px) and (min-aspect-ratio: 1/1) {
            max-height: 0;
        }
        // @media (min-aspect-ratio: 1/1) {
        //   max-height: unset;
        // }
        //
    }
}

.geneeNotesSuggestions::part(content) {
  @media only screen and (min-width: 620px) {
      max-width: 600px;
      left: calc(50% - 300px) !important;
  }
}

// Hide trash button in the event calendar tooltip
.dx-overlay-wrapper.dx-scheduler-appointment-tooltip-wrapper .dx-tooltip-appointment-item-delete-button.dx-button.dx-button-normal.dx-button-mode-text.dx-widget.dx-button-has-icon {
  display: none;
}

ion-popover .popover-viewport {
  position: unset !important;
  display: unset !important;
  width: unset;
  height: unset;
  color: var(--ion-text-color, #000);
  background-color: unset;
  border: unset;
  padding: unset;
  margin: unset;
  overflow: unset;
}

//It's causing https://exeevo.atlassian.net/browse/OMNI-23460, which is why commenting it. It was done as part of OMNI-19941.
// .sc-ion-modal-ios-h:first-of-type{
//   --backdrop-opacity: unset !important;
// }

.alert-radio-label.sc-ion-alert-ios{
  color: unset !important;
}

.omni-info-modal{
  &::part(content) {
    --height: auto;
    --min-height: 150px;
    --border-radius:6px;
    --width:80%;
    --max-width : 500px;
  }
  .ion-page{
    position: relative;
    contain: layout style;
    height: 100%;
  }
}

.omni-alert-modal {
  @extend .omni-info-modal;
  &::part(content) {
    --max-width: 360px;
  }
}

.omni-option-modal {
  @extend .omni-info-modal;
  &::part(content) {
    --max-width: 375px;
  }
}

.omni-notification-modal-md{
  @extend .omni-info-modal;
  &::part(content) {
    --max-width: 380px;
  }
}

ion-modal.omni-notification-modal.ios.modal-default.show-modal {
  @extend .omni-alert-modal;
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.4);
  &::part(content) {
    --min-height: auto;
    --height: auto;
  }
  .wrapper{
    padding: 1rem;
  }
  .alert-btn{
    height: 24px;
    margin-top: unset !important;
    margin-bottom: unset !important;
    min-height: fit-content !important;
  }
}


// new changes
ion-modal.auto-height {
  --height: auto !important;
}

ion-modal.auto-height::part(content) {
  // position: relative;
  display: block !important;
  contain: content !important;
  --height: auto !important;
  position:absolute;
  bottom: 0;
}

ion-modal.auto-height .inner-content {
  max-height: 100vh !important;
  overflow: auto !important;
}


[hidden] {
  display: none !important;
}
