//Custom Ionic Icons
// Overriding the ion-icon behavior
// All custom icons will start with "indegeneIcon-" prefix
ion-icon {
    // Any icon name starting with indegene to be matched
    &[ng-reflect-name*="indegene-"],
    &[class*="indegene-"] {
        // Instead of using the font-based icons applying SVG masks
        // mask-size: contain;
        // mask-position: 50% 50%;
        // mask-repeat: no-repeat;
        // background: currentColor;
        background-size: contain;
        background-position: 50% 50%;
        background-repeat: no-repeat;
        width: 1em;
        height: 1em;
    }
    //TODO: Need to fix urls. commented for now
    // // add icon
    &[ng-reflect-name*="indegene-iAdd"] {
        background-image: url(../assets/imgs/ios_add.png);
    }
    &[ng-reflect-name*="indegene-iAddL"] {
        background-image: url(../assets/imgs/ios_add_3x.svg);
    }
    &[ng-reflect-name*="indegene-toReason"] {
        background-image: url(../assets/imgs/to_icon.svg);
    }
    &[ng-reflect-name*="indegene-account-management"] {
        background-image: url(../assets/imgs/tools_account_management.svg);
    }
    &[ng-reflect-name*="indegene-accounts"] {
        background-image: url(../assets/imgs/tools_accounts.svg);
    }
    &[ng-reflect-name*="indegene-joint_appointment"] {
        background-image: url(../assets/imgs/joint_appointment.svg);
    }
    &[ng-reflect-name*="indegene-analytics"] {
        background-image: url(../assets/imgs/tools_analytics.svg);
    }
    &[ng-reflect-name*="indegene-calendar"] {
        background-image: url(../assets/imgs/tools_calendar.svg);
    }
    &[ng-reflect-name*="indegene-call-plan"] {
        background-image: url(../assets/imgs/tools_call_plans.svg);
    }
    &[ng-reflect-name*="indegene-coaching"] {
        background-image: url(../assets/imgs/tools_coaching.svg);
    }
    &[ng-reflect-name*="indegene-consent"] {
        background-image: url(../assets/imgs/consent-signature.svg);
    }
    &[ng-reflect-name*="indegene-allocation"] {
        background-image: url(../assets/imgs/tools_customer_allocations.svg);
    }
    &[ng-reflect-name*="indegene-presentation"] {
        background-image: url(../assets/imgs/tools_presentations.svg);
    }
    &[ng-reflect-name*="notification-assets/imgs/tools_presentations.svg"],
    &[class*="notification-assets/imgs/tools_presentations.svg"] {
      background-image: url(../assets/imgs/tools_presentations.svg);
    }
    &[ng-reflect-name*="notification-assets/imgs/quotes_tool.svg"],
    &[class*="notification-assets/imgs/quotes_tool.svg"] {
      background-image: url(../assets/imgs/quotes_tool.svg);
    }
    &[ng-reflect-name*="indegene-resources"] {
        background-image: url(../assets/imgs/tools_resources.svg);
    }
    &[ng-reflect-name*="notification-assets/imgs/tools_resources.svg"],
    &[class*="notification-assets/imgs/tools_resources.svg"] {
      background-image: url(../assets/imgs/tools_resources.svg);
    }
    &[ng-reflect-name*="indegene-time-off"] {
        background-image: url(../assets/imgs/tools_timeoff.svg);
    }
    &[ng-reflect-name*="indegene-contact"] {
        background-image: url(../assets/imgs/tools_contacts.svg);
    }
    &[ng-reflect-name*="indegene-case-intake"],
    &[ng-reflect-name*="indegene-medical_inquiry"],
    &[ng-reflect-name*="indegene-CaseIntake"] {
        background-image: url(../assets/imgs/case-intake.svg);
    }
    &[ng-reflect-name*="indegene-scientific-plan"] {
        background-image: url(../assets/imgs/scientific-plan.svg);
    }
    &[ng-reflect-name*="opportunity_mgmt_tool"] {
        background-image: url(../assets/imgs/opportunity_mgmt_tool.svg);
    }
    &[ng-reflect-name*="indegene-manage-list"] {
      background-image: url(../assets/imgs/manage-list.svg);
  }
  &[ng-reflect-name*="indegene-return-down"] {
    background-image: url(../assets/imgs/return_down.svg);
}
&[ng-reflect-name*="indegene-comments"] {
  background-image: url(../assets/imgs/comments.svg);
}
    &[ng-reflect-name*="market_scan_tool_icon"] {
        background-image: url(../assets/imgs/market_scan_tool_icon.svg);
    }
    &[ng-reflect-name*="events_tool_icon"] {
        background-image: url(../assets/imgs/events_tool_icon.svg);
    }
    &[ng-reflect-name*="indegene-ordermgmt"] {
        background-image: url(../assets/imgs/tools_order_mgmt.svg);
    }
    &[ng-reflect-name*="indegene-email"],
    &[ng-reflect-name*="indegene-Email"] {
        background-image: url(../assets/imgs/email.svg);
    }
    &[ng-reflect-name*="indegene-appointment"] {
        background-image: url(../assets/imgs/appointment.svg);
    }
    &[ng-reflect-name*="indegene-indskr_sampledrop"],
    &[ng-reflect-name*="indegene-indskr_order"],
    &[ng-reflect-name*="indegene-Sample"] {
        background-image: url(../assets/imgs/tools_customer_allocations.png);
    }
    &[ng-reflect-name*="indegene-task"],
    &[ng-reflect-name*="indegene-followuptask"] {
        background-image: url(../assets/imgs/follow-up_32x32.svg);
    }

    &[ng-reflect-name*="indegene-child"] {
        background-image: url(../assets/imgs/child_item.svg);
    }

    &[ng-reflect-name*="indegene-phonecall"],
    &[ng-reflect-name*="indegene-assets/imgs/phone-call-activity.svg"],
    &[class*="indegene-assets/imgs/phone-call-activity.svg"] {
        background-image: url(../assets/imgs/phone-call-activity.svg);
    }
    &[ng-reflect-name*="indegene-assets/imgs/phone-call-completed.svg"],
    &[class*="indegene-assets/imgs/phone-call-completed.svg"] {
        background-image: url(../assets/imgs/phone-call-completed.svg);
    }
    &[class*="indegene-assets/imgs/kit-booking.svg"] {
      background-image: url(../assets/imgs/kit-booking.svg);
    }
    &[class*="indegene-assets/imgs/kit-booking-red.svg"] {
      background-image: url(../assets/imgs/kit-booking-red.svg);
    }
    &[ng-reflect-name*="indegene-geniee"] {
        background-image: url(../assets/imgs/ogeenee.png);
    }
    &[ng-reflect-name*="indegene-hmenu"] {
        background-image: url(../assets/imgs/horizontal-menu-filled.svg);
    }
    &[ng-reflect-name*="indegene-thumbs-up"] {
        background-image: url(../assets/imgs/feedback_thumbs_up.svg);
    }
    &[ng-reflect-name*="indegene-thumbs-up-active"] {
        background-image: url(../assets/imgs/feedback_thumbs_up_active.svg);
    }
    &[ng-reflect-name*="indegene-thumbs-down"] {
        background-image: url(../assets/imgs/feedback_thumbs_down.svg);
    }
    &[ng-reflect-name*="indegene-thumbs-down-active"] {
        background-image: url(../assets/imgs/feedback_thumbs_down_active.svg);
    }
    // &[ng-reflect-name*="indegene-xp-central"] {
    //   background-image: url(../assets/imgs/xp_central.svg);
    // }
    &[ng-reflect-name*="indegene-xp-genee"] {
        background-image: url(../assets/imgs/xp_genee.svg);
    }
    &[ng-reflect-name*="indegene-xp-genee-active"] {
        background-image: url(../assets/imgs/xp_genee_active.svg);
    }
    // &[ng-reflect-name*="indegene-xp-predictions"] {
    //   background-image: url(../assets/imgs/xp_predictions.svg);
    // }
    &[ng-reflect-name*="indegene-xp-save"] {
        background-image: url(../assets/imgs/xp_save.svg);
    }

    &[ng-reflect-name*="indegene-down-arrow"] {
      background-image: url(../assets/imgs/down-arrow.svg);
  }

    &[ng-reflect-name*="indegene-xp-save-active"] {
        background-image: url(../assets/imgs/xp_save_active.svg);
    }
    // &[ng-reflect-name*="indegene-xp-team"] {
    //   background-image: url(../assets/imgs/xp_team.svg);
    // }
    &[ng-reflect-name*="indegene-xp-low-strength"] {
        background-image: url(../assets/imgs/xp_low_strength_bar.svg);
    }
    &[ng-reflect-name*="indegene-xp-medium-strength"] {
        background-image: url(../assets/imgs/xp_medium_strength_bar.svg);
    }
    &[ng-reflect-name*="indegene-xp-team-high-strength"] {
        background-image: url(../assets/imgs/xp_high_strength_bar.svg);
    }
    &[ng-reflect-name*="indegene-bookmark"] {
        background-image: url(../assets/imgs/indegene_bookmark.svg);
    }
    &[ng-reflect-name*="indegene-bookmark-saved"] {
        background-image: url(../assets/imgs/indegene_bookmark_saved.svg);
    }
    &[ng-reflect-name*="indegene-consent-signature"] {
        background-image: url(../assets/imgs/consent-signature.svg);
    }
    &[ng-reflect-name*="indegene-assets/imgs/follow-up_32x32.svg"],
    &[class*="indegene-assets/imgs/follow-up_32x32.svg"] {
        background-image: url(../assets/imgs/follow-up_32x32.svg);
    }
    &[ng-reflect-name*="indegene-assets/imgs/alert.svg"],
    &[class*="indegene-assets/imgs/alert.svg"] {
        background-image: url(../assets/imgs/alert.svg);
    }
    &[ng-reflect-name*="indegene-assets/imgs/live_meet.svg"],
    &[class*="indegene-assets/imgs/live_meet.svg"] {
        background-image: url(../assets/imgs/live_meet.svg);
    }
    &[ng-reflect-name*="indegene-assets/imgs/joint_appointment.svg"],
    &[class*="indegene-assets/imgs/joint_appointment.svg"] {
        background-image: url(../assets/imgs/joint_appointment.svg);
    }
    &[ng-reflect-name*="indegene-assets/imgs/Joint_appointment_complete.svg"],
    &[class*="indegene-assets/imgs/Joint_appointment_complete.svg"] {
        background-image: url(../assets/imgs/Joint_appointment_complete.svg);
    }
    &[ng-reflect-name*="indegene-assets/imgs/appointment.svg"],
    &[class*="indegene-assets/imgs/appointment.svg"] {
        background-image: url(../assets/imgs/appointment.svg);
    }
    &[ng-reflect-name*="indegene-assets/imgs/storecheck.svg"],
    &[class*="indegene-assets/imgs/storecheck.svg"] {
        background-image: url(../assets/imgs/storecheck.svg);
    }
    &[ng-reflect-name*="indegene-assets/imgs/account_visit.svg"],
    &[class*="indegene-assets/imgs/account_visit.svg"] {
        background-image: url(../assets/imgs/account_visit.svg);
    }
    &[ng-reflect-name*="indegene-assets/imgs/open-joint-phonecall.svg"],
    &[class*="indegene-assets/imgs/open-joint-phonecall.svg"] {
        background-image: url(../assets/imgs/open-joint-phonecall.svg);
    }
    &[ng-reflect-name*="indegene-assets/imgs/completed-joint-phonecall.svg"],
    &[class*="indegene-assets/imgs/completed-joint-phonecall.svg"] {
        background-image: url(../assets/imgs/completed-joint-phonecall.svg);
    }
    &[ng-reflect-name*="indegene-assets/imgs/remote_meeting_open.svg"],
    &[class*="indegene-assets/imgs/remote_meeting_open.svg"] {
        background-image: url(../assets/imgs/remote_meeting_open.svg);
    }
    &[ng-reflect-name*="indegene-assets/imgs/remote_meeting_completed.svg"],
    &[class*="indegene-assets/imgs/remote_meeting_completed.svg"] {
        background-image: url(../assets/imgs/remote_meeting_completed.svg);
    }
    &[ng-reflect-name*="indegene-assets/imgs/open-recommendation-meeting.svg"],
    &[class*="indegene-assets/imgs/open-recommendation-meeting.svg"] {
        background-image: url(../assets/imgs/open-recommendation-meeting.svg);
    }
    &[ng-reflect-name*="indegene-assets/imgs/completed-recommendation-meeting.svg"],
    &[class*="indegene-assets/imgs/completed-recommendation-meeting.svg"] {
        background-image: url(../assets/imgs/completed-recommendation-meeting.svg);
    }
    &[ng-reflect-name*="indegene-assets/imgs/open_joint_recommended_meeting.svg"],
    &[class*="indegene-assets/imgs/open_joint_recommended_meeting.svg"] {
        background-image: url(../assets/imgs/open_joint_recommended_meeting.svg);
    }
    &[ng-reflect-name*="indegene-assets/imgs/completed_joint_recommended_meeting.svg"],
    &[class*="indegene-assets/imgs/completed_joint_recommended_meeting.svg"] {
        background-image: url(../assets/imgs/completed_joint_recommended_meeting.svg);
    }
    &[ng-reflect-name*="indegene-assets/imgs/open-recommendation-remote-meeting.svg"],
    &[class*="indegene-assets/imgs/open-recommendation-remote-meeting.svg"] {
        background-image: url(../assets/imgs/open-recommendation-remote-meeting.svg);
    }
    &[ng-reflect-name*="indegene-assets/imgs/completed-recommendation-remote-meeting.svg"],
    &[class*="indegene-assets/imgs/completed-recommendation-remote-meeting.svg"] {
        background-image: url(../assets/imgs/completed-recommendation-remote-meeting.svg);
    }
    &[ng-reflect-name*="indegene-assets/imgs/joint_remote_meeting_open.svg"],
    &[class*="indegene-assets/imgs/joint_remote_meeting_open.svg"] {
        background-image: url(../assets/imgs/joint_remote_meeting_open.svg);
    }
    &[ng-reflect-name*="indegene-assets/imgs/joint_remote_meeting_completed.svg"],
    &[class*="indegene-assets/imgs/joint_remote_meeting_completed.svg"] {
        background-image: url(../assets/imgs/joint_remote_meeting_completed.svg);
    }
    &[ng-reflect-name*="indegene-assets/imgs/phone-call.png"],
    &[class*="indegene-assets/imgs/phone-call.png"] {
        background-image: url(../assets/imgs/phone-call.png);
    }
    &[ng-reflect-name*="indegene-assets/imgs/email.svg"],
    &[class*="indegene-assets/imgs/email.svg"] {
        background-image: url(../assets/imgs/email.svg);
    }
    &[ng-reflect-name*="indegene-assets/imgs/email-black.svg"],
    &[class*="indegene-assets/imgs/email-black.svg"] {
        background-image: url(../assets/imgs/email-black.svg);
    }
    &[ng-reflect-name*="indegene-assets/imgs/envelope-pendingsyn.svg"],
    &[ng-reflect-name*="indegene-email_orange"],
    &[class*="indegene-assets/imgs/envelope-pendingsyn.svg"] {
        background-image: url(../assets/imgs/envelope-pendingsyn.svg);
    }
    &[ng-reflect-name*="indegene-assets/imgs/email-red.svg"],
    &[ng-reflect-name*="indegene-email-red.svg"],
    &[class*="indegene-assets/imgs/email-red.svg"] {
        background-image: url(../assets/imgs/email-red.svg);
    }
    &[ng-reflect-name*="indegene-assets/imgs/email-green.svg"],
    &[ng-reflect-name*="indegene-email-green.svg"],
    &[class*="indegene-assets/imgs/email-green.svg"] {
        background-image: url(../assets/imgs/email-green.svg);
    }
    &[ng-reflect-name*="indegene-assets/imgs/recommendation-email.svg"],
    &[class*="indegene-assets/imgs/recommendation-email.svg"] {
        background-image: url(../assets/imgs/recommendation-email.svg);
    }
    &[ng-reflect-name*="indegene-assets/imgs/recommendation-email-black.svg"],
    &[class*="indegene-assets/imgs/recommendation-email-black.svg"] {
        background-image: url(../assets/imgs/recommendation-email-black.svg);
    }
    &[ng-reflect-name*="indegene-assets/imgs/recommendation-email-yellow.svg"],
    &[ng-reflect-name*="indegene-recommendation-email-yellow.svg"],
    &[class*="indegene-assets/imgs/recommendation-email-yellow.svg"] {
        background-image: url(../assets/imgs/recommendation-email-yellow.svg);
    }
    &[ng-reflect-name*="indegene-assets/imgs/recommendation-email-red.svg"],
    &[ng-reflect-name*="indegene-recommendation-email-red.svg"],
    &[class*="indegene-assets/imgs/recommendation-email-red.svg"] {
        background-image: url(../assets/imgs/recommendation-email-red.svg);
    }
    &[ng-reflect-name*="indegene-assets/imgs/recommendation-email-green.svg"],
    &[ng-reflect-name*="indegene-recommendation-email-green.svg"],
    &[class*="indegene-assets/imgs/recommendation-email-green.svg"] {
        background-image: url(../assets/imgs/recommendation-email-green.svg);
    }
    &[ng-reflect-name*="indegene-assets/imgs/tot_approved_status.svg"],
    &[class*="indegene-assets/imgs/tot_approved_status.svg"] {
        background-image: url(../assets/imgs/tot_approved_status.svg);
    }
    &[ng-reflect-name*="indegene-assets/imgs/tot_unapproved_status.svg"],
    &[class*="indegene-assets/imgs/tot_unapproved_status.svg"] {
        background-image: url(../assets/imgs/tot_unapproved_status.svg);
    }
  
  &[class*="closebutton.svg"] {
    background-image: url(../assets/imgs/closebutton.svg);
}
&[class*="expandblue.svg"] {
  background-image: url(../assets/imgs/expandblue.svg);
}
&[class*="searchblue.svg"] {
  background-image: url(../assets/imgs/searchblue.svg);
}
    // &[ng-reflect-name*="indegene-assets/imgs/sample_activity_agendaIcon.svg"], &[ng-reflect-name*="indegene-sample_activity"], &[class*="indegene-assets/imgs/sample_activity_agendaIcon.svg"] {
    //     background-image: url(../assets/imgs/sample_activity_agendaIcon.svg);
    // }
    // &[ng-reflect-name*="indegene-assets/imgs/sample_activity_agenda_complete.svg"], &[ng-reflect-name*="indegene-sample_activity_completed"], &[class*="indegene-assets/imgs/sample_activity_agenda_completed.svg"] {
    //     background-image: url(../assets/imgs/sample_activity_agenda_complete.svg);
    // }
    &[ng-reflect-name*="indegene-assets/imgs/follow-up-complete.svg"],
    &[class*="indegene-assets/imgs/follow-up-complete.svg"] {
        background-image: url(../assets/imgs/follow-up-complete.svg);
    }
    &[ng-reflect-name*="indegene-assets/imgs/order_open.svg"],
    &[class*="indegene-assets/imgs/order_open.svg"],
    &[ng-reflect-name*="notification-assets/imgs/order_open.svg"],
    &[class*="notification-assets/imgs/order_open.svg"] {
        background-image: url(../assets/imgs/order_open.svg);
    }
    &[ng-reflect-name*="indegene-assets/imgs/order_cancel.svg"],
    &[class*="indegene-assets/imgs/order_cancel.svg"],
    &[ng-reflect-name*="notification-assets/imgs/order_cancel.svg"],
    &[class*="notification-assets/imgs/order_cancel.svg"] {
        background-image: url(../assets/imgs/order_cancel.svg);
    }

    &[ng-reflect-name*="indegene-assets/imgs/meeting_cancel.svg"],
    &[class*="indegene-assets/imgs/meeting_cancel.svg"],
    &[ng-reflect-name*="notification-assets/imgs/meeting_cancel.svg"],
    &[class*="notification-assets/imgs/meeting_cancel.svg"] {
        background-image: url(../assets/imgs/meeting_cancel.svg);
    }
    &[ng-reflect-name*="indegene-assets/imgs/sales-data.svg"],
    &[class*="indegene-assets/imgs/sales-data.svg"] {
        background-image: url(../assets/imgs/sales-data.svg);
    }
    &[ng-reflect-name*="indegene-assets/imgs/orders_complete.svg"],
    &[class*="indegene-assets/imgs/orders_complete.svg"] {
        background-image: url(../assets/imgs/orders_complete.svg);
    }
    &[ng-reflect-name*="indegene-assets/imgs/order_complete.svg"],
    &[class*="indegene-assets/imgs/order_complete.svg"] {
        background-image: url(../assets/imgs/order_complete.svg);
    }
    &[ng-reflect-name*="indegene-assets/imgs/orders_pending.svg"],
    &[class*="indegene-assets/imgs/orders_pending.svg"] {
        background-image: url(../assets/imgs/orders_pending.svg);
    }
    &[ng-reflect-name*="indegene-assets/imgs/case-intake.svg"],
    &[class*="indegene-assets/imgs/case-intake.svg"] {
        background-image: url(../assets/imgs/case-intake.svg);
    }
    &[ng-reflect-name*="indegene-assets/imgs/case-intake-completed.svg"],
    &[ng-reflect-name*="case-intake-completed"],
    &[class*="indegene-assets/imgs/case-intake-completed.svg"] {
        background-image: url(../assets/imgs/case-intake-completed.svg);
    }
    &[ng-reflect-name*="indegene-xp-thumbs-up"] {
        background-image: url(../assets/imgs/xp_thumbs_up.svg);
    }
    &[ng-reflect-name*="indegene-xp-thumbs-up-active"] {
        background-image: url(../assets/imgs/xp_thumbs_up_active.svg);
    }
    &[ng-reflect-name*="indegene-xp-thumbs-down"] {
        background-image: url(../assets/imgs/xp_thumbs_down.svg);
    }
    &[ng-reflect-name*="indegene-xp-thumbs-down-active"] {
        background-image: url(../assets/imgs/xp_thumbs_down_active.svg);
    }
    &[ng-reflect-name*="indegene-xp-predictions-left"] {
        background-image: url(../assets/imgs/contact_predictions_left.svg);
    }
    &[ng-reflect-name*="indegene-xp-trending-score-left"] {
        background-image: url(../assets/imgs/contact_trending_score_left.svg);
    }
    &[ng-reflect-name*="indegene-assets/imgs/appointment_complete.svg"],
    &[class*="indegene-assets/imgs/appointment_complete.svg"] {
        background-image: url(../assets/imgs/appointment_complete.svg);
    }
    &[ng-reflect-name*="indegene-back-to-home-btn"] {
        background-image: url(../assets/imgs/back_to_home_btn.svg);
    }
    &[ng-reflect-name*="indegene-selectors-add-icon"] {
        background-image: url(../assets/imgs/selectors_add.svg);
    }
    &[ng-reflect-name*="indegene-selectors-remove-icon"] {
        background-image: url(../assets/imgs/selectors_remove.svg);
    }
    &[ng-reflect-name*="indegene-selectors-checkmark-icon"] {
        background-image: url(../assets/imgs/selectors_checkmark.svg);
    }
    &[ng-reflect-name*="indegene-consent-alert"] {
        background-image: url(../assets/imgs/messaging-consent-alert.png);
    }
    &[ng-reflect-name*="indegene-consent-main"] {
      background-image: url(../assets/imgs/consent_main.svg);
    }
    &[ng-reflect-name*="indegene-eligibility"] {
      background-image: url(../assets/imgs/eligibility.svg);
    }
    &[ng-reflect-name*="indegene-ellipsis"] {
      background-image: url(../assets/imgs/ellipsis.svg);
    }
    &[ng-reflect-name*="indegene-filter-blue"] {
      background-image: url(../assets/imgs/filter-blue.svg);
    }
    &[ng-reflect-name*="indegene-consent-optout"] {
      background-image: url(../assets/imgs/consent_optout.svg);
    }
    &[ng-reflect-name*="indegene-alert-close"] {
      background-image: url(../assets/imgs/alert_close.svg);
    }
    &[ng-reflect-name*="indegene-presentation-downloaded"],
    &[class*="indegene-event-signup-accepted"],
    &[ng-reflect-name*="indegene-event-signup-accepted"] {
        background-image: url(../assets/imgs/green_indicator.svg);
    }
    &[ng-reflect-name*="indegene-event-signup-declined"],
    &[class*="indegene-event-signup-declined"] {
        background-image: url(../assets/imgs/event-signup-declined.svg);
    }
    &[ng-reflect-name*="indegene-email-white"] {
        background-image: url(../assets/imgs/email_white.svg);
    }
    &[ng-reflect-name*="indegene-email_blue-white"] {
        background-image: url(../assets/imgs/message_check_blue_white.svg);
    }
    &[ng-reflect-name*="indegene-email_orange-white"] {
        background-image: url(../assets/imgs/message_check_orange_white.svg);
    }
    &[ng-reflect-name*="indegene-email_red-white"] {
        background-image: url(../assets/imgs/message_check_red_white.svg);
    }
    &[ng-reflect-name*="indegene-email_green-white"] {
        background-image: url(../assets/imgs/email_green_white.svg);
    }
    &[ng-reflect-name*="indegene-email_green-white"] {
        background-image: url(../assets/imgs/email_green_white.svg);
    }
    &[ng-reflect-name*="indegene-sample_activity-white"] {
        background-image: url(../assets/imgs/open_sample_activity_white.svg);
    }
    &[ng-reflect-name*="indegene-sample_activity_completed-white"] {
        background-image: url(../assets/imgs/compl_sample_activity_white.svg);
    }
    &[ng-reflect-name*="indegene-case-intake-white"] {
        background-image: url(../assets/imgs/customer_Inquiry_3x_white.svg);
    }
    &[ng-reflect-name*="indegene-case-intake-completed-white"] {
        background-image: url(../assets/imgs/case-intake-completed_white.svg);
    }
    &[ng-reflect-name*="indegene-search-share-default"] {
        background-image: url(../assets/imgs/share_search_default.svg);
    }
    &[ng-reflect-name*="indegene-search-share-pending"] {
        background-image: url(../assets/imgs/share_search_pending.svg);
    }
    &[ng-reflect-name*="indegene-search-share-success"] {
        background-image: url(../assets/imgs/share_search_success.svg);
    }
    &[ng-reflect-name*="indegene-ios-close-icon"] {
        background-image: url(../assets/imgs/ios-close-button.svg);
    }
    &[ng-reflect-name*="indegene-edit-icon"] {
        background-image: url(../assets/imgs/scheduling_edit_icon.svg);
    }
    &[ng-reflect-name*="indegene-account-health-high"] {
        background-image: url(../assets/imgs/high_health.svg);
    }
    &[ng-reflect-name*="indegene-account-health-medium"] {
        background-image: url(../assets/imgs/medium_health.svg);
    }
    &[ng-reflect-name*="indegene-account-health-low"] {
        background-image: url(../assets/imgs/low_health.svg);
    }
    &[ng-reflect-name*="indegene-account-predictions"] {
        background-image: url(../assets/imgs/predictions.svg);
    }
    &[ng-reflect-name*="indegene-phone-icon"] {
        background-image: url(../assets/imgs/phone_icon.svg);
    }
    &[ng-reflect-name*="indegene-search"] {
      background-image: url(../assets/imgs/search-grey-icon.svg);
    }
    &[ng-reflect-name*="indegene-filter"] {
      background-image: url(../assets/imgs/filter-grey.svg);
    }
    &[ng-reflect-name*="indegene-assets/imgs/tools_coaching.svg"],
    &[class*="indegene-assets/imgs/tools_coaching.svg"] {
        background-image: url(../assets/imgs/tools_coaching.svg);
    }
    &[ng-reflect-name*="indegene-tools_link"] {
        background-image: url(../assets/imgs/tools_link.svg);
    }
    &[ng-reflect-name*="indegene-assets/imgs/transfer_down.svg"],
    &[class*="indegene-assets/imgs/transfer_down.svg"] {
        background-image: url(../assets/imgs/transfer_down.svg);
    }
    &[ng-reflect-name*="indegene-assets/imgs/transfer_To.svg"],
    &[class*="indegene-assets/imgs/transfer_To.svg"] {
        background-image: url(../assets/imgs/transfer_To.svg);
    }
    &[ng-reflect-name*="indegene-assets/imgs/transfer_From.svg"],
    &[class*="indegene-assets/imgs/transfer_From.svg"] {
        background-image: url(../assets/imgs/transfer_From.svg);
    }
    &[ng-reflect-name*="indegene-assets/imgs/copy_white.svg"],
    &[class*="indegene-copy_white"] {
        background-image: url(../assets/imgs/copy_white.svg);
    }
    &[ng-reflect-name*="notification-assets/imgs/service_error.svg"],
    &[class*="notification-assets/imgs/service_error.svg"] {
      background-image: url(../assets/imgs/service_error.svg);
    }
    &[ng-reflect-name*="notification-assets/imgs/shared_search.svg"],
    &[class*="notification-assets/imgs/shared_search.svg"] {
      background-image: url(../assets/imgs/shared_search.svg);
    }
    &[ng-reflect-name*="notification-assets/imgs/sync.svg"],
    &[class*="notification-assets/imgs/sync.svg"] {
      background-image: url(../assets/imgs/sync.svg);
    }
    &[ng-reflect-name*="indegene-assets/imgs/notifications-threedots.svg"],
    &[class*="indegene-assets/imgs/notifications-threedots.svg"] {
      background-image: url(../assets/imgs/notifications-threedots.svg);
    }
    &[ng-reflect-name*="indegene-assets/imgs/notification_bluedot.svg"],
    &[class*="indegene-assets/imgs/notification_bluedot.svg"] {
      background-image: url(../assets/imgs/notification_bluedot.svg);
    }
    &[ng-reflect-name*="notification-assets/imgs/contacts_notifications.svg"],
    &[class*="notification-assets/imgs/contacts_notifications.svg"] {
      background-image: url(../assets/imgs/contacts_notifications.svg);
    }
    &[ng-reflect-name*="notification-assets/imgs/accounts_notification.svg"],
    &[class*="notification-assets/imgs/accounts_notification.svg"] {
      background-image: url(../assets/imgs/accounts_notification.svg);
    }
    &[ng-reflect-name*="notification-assets/imgs/presentation_notification.svg"],
    &[class*="notification-assets/imgs/presentation_notification.svg"] {
      background-image: url(../assets/imgs/presentation_notification.svg);
    }
    &[ng-reflect-name*="notification-assets/imgs/resource_notification.svg"],
    &[class*="notification-assets/imgs/resource_notification.svg"] {
      background-image: url(../assets/imgs/resource_notification.svg);
    }
    &[ng-reflect-name*="notification-assets/imgs/appointment.svg"],
    &[class*="notification-assets/imgs/appointment.svg"] {
      background-image: url(../assets/imgs/appointment.svg);
    }
    &[ng-reflect-name*="notification-assets/imgs/sample_activity_agendaIcon.svg"],
    &[class*="notification-assets/imgs/sample_activity_agendaIcon.svg"] {
      background-image: url(../assets/imgs/sample_activity_agendaIcon.svg);
    }
    &[ng-reflect-name*="notification-assets/imgs/phone-call-activity.svg"],
    &[class*="notification-assets/imgs/phone-call-activity.svg"] {
      background-image: url(../assets/imgs/phone-call-activity.svg);
    }
    &[ng-reflect-name*="notification-assets/imgs/email.svg"],
    &[class*="notification-assets/imgs/email.svg"] {
      background-image: url(../assets/imgs/email.svg);
    }
    &[ng-reflect-name*="notification-assets/imgs/follow-up_32x32.svg"],
    &[class*="notification-assets/imgs/follow-up_32x32.svg"] {
      background-image: url(../assets/imgs/follow-up_32x32.svg);
    }
    &[ng-reflect-name*="notification-assets/imgs/case-intake.svg"],
    &[class*="notification-assets/imgs/case-intake.svg"] {
      background-image: url(../assets/imgs/case-intake.svg);
    }
    &[ng-reflect-name*="notification-assets/imgs/phone-call-activity.svg"],
    &[class*="notification-assets/imgs/phone-call-activity.svg"] {
      background-image: url(../assets/imgs/phone-call-activity.svg);
    }
    &[ng-reflect-name*="notification-assets/imgs/orders_pending.svg"],
    &[class*="notification-assets/imgs/orders_pending.svg"] {
      background-image: url(../assets/imgs/orders_pending.svg);
    }
    &[ng-reflect-name*="notification-assets/imgs/thumbnail_default.png"],
    &[class*="notification-assets/imgs/thumbnail_default.png"] {
      background-image: url(../assets/imgs/thumbnail_default.png);
    }
    &[ng-reflect-name*="notification-assets/imgs/notifications-active.svg"],
    &[class*="notification-assets/imgs/notifications-active.svg"] {
      background-image: url(../assets/imgs/notifications-active.svg);
    }
    &[ng-reflect-name*="notification-assets/imgs/customer-inquiry-active.svg"],
    &[class*="notification-assets/imgs/customer-inquiry-active.svg"] {
      background-image: url(../assets/imgs/customer-inquiry-active.svg);
    }
    &[ng-reflect-name*="notification-assets/imgs/manage_list.svg"],
    &[class*="notification-assets/imgs/manage_list.svg"] {
      background-image: url(../assets/imgs/manage_list.svg);
    }
    &[ng-reflect-name*="notification-assets/imgs"],
    &[class*="notification-assets/imgs"] {
      // background-size: contain;
      background-position: 50% 50%;
      background-repeat: no-repeat;
      width: 1em;
      height: 1em;
    }
    &[ng-reflect-name*="indegene-info_default-white"] {
      background-image: url(../assets/imgs/info_default.svg);
  }
  &[ng-reflect-name*="opportunity_mgmt_tool"] {
    background-image: url(../assets/imgs/Opportunities_3x.svg);
}
&[ng-reflect-name*="opportunity-red-indicator"] {
    background-image: url(../assets/imgs/red-indicator.svg);
}
}
